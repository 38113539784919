import React, {Component} from "react";
import {connect} from "react-redux";
import {CARD_STYLE_NO_HOVER} from "../../utils/dummy";
import ZeroStateArea from "../atoms/ZeroStateArea";
import { deleteExperience } from "../../api/users";
import ProfileSectionHeader from "./ProfileSectionHeader";
import ProfileExperienceItem from "./ProfileExperienceItem";
import AddLinkedinModal from "../modals/AddLinkedinModal";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class ProfileAboutExperience extends Component {
    state = {
        addLinkedinModalOpen: false,
        isEditingExistingItems: false,
    }

    toggleEditExistingMode = () => {
        this.setState({isEditingExistingItems: !this.state.isEditingExistingItems});
    }

    openAddExperienceModal = () => {
        this.setState({addLinkedinModalOpen: true})
    }

    deleteExperience = async (index) => {
        await deleteExperience(this.props.authUser, String(index));
        window.location.reload();
    }

    render() {
        const experiences = this.props.experiences
        const user = this.props.user
        const prefix = this.props.sameUser ? "My" : `${user.first_name}'s`;
        return (
            <div className={`${CARD_STYLE_NO_HOVER}`}>
                <ProfileSectionHeader
                    label={`${prefix} Work`}
                    sameUser={this.props.sameUser}
                    editing={this.state.isEditingExistingItems}
                    setEditingMode={this.toggleEditExistingMode}
                    onClickAdd={this.openAddExperienceModal}
                    hideAdd={!!user.linkedin}
                />
                {experiences.map((item, index) => {
                        return (
                            <ProfileExperienceItem
                                item={item}
                                index={index}
                                onClickEdit={this.openAddEducationModal}
                                onClickDelete={this.deleteExperience}
                                editing={this.state.isEditingExistingItems}
                            />
                        )
                    }
                )}
                {experiences.length === 0 ?
                    <ZeroStateArea
                        title={'Nothing here...'}
                        sub={`${user.first_name} does not have any work experience listed yet. ${this.props.sameUser && "If you uploaded your LinkedIn and still aren't seeing anything please reach out to hello@starfishapp.com."}`}
                    />
                    :
                    null
                }
                <AddLinkedinModal 
                    authUser={this.props.authUser}
                    isOpen={this.state.addLinkedinModalOpen}
                    close={() => this.setState({addLinkedinModalOpen: false})}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps)(ProfileAboutExperience)